.team-presentation {
    background-color: rgba(0, 0, 0, 0.5);
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.team-presentation-team-data {
    font-size: 60px;
    display: flex;
    width: 100%;
}

.team-presentation-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 48px;
    color: white;
    flex: 1;

    padding-left: 90px;
    padding-right: 20px;
    padding-bottom: 5px;
    text-transform: uppercase;
    background-color: midnightblue;
}

.team-presentation-group {
    display: flex;
    align-items: center;
    justify-content: flex-right;
    font-size: 48px;
    color: white;
    padding-right: 20px;
    padding-bottom: 5px;
    text-transform: uppercase;
    background-color: midnightblue;
}

.team-presentation-logo {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    background-color: midnightblue;
    z-index: 10;
    position: absolute;
    top: -22px;
    left: -70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-presentation-logo > img {

    width: 90%;
 
}

.team-presentation-players-presentation {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}