.player-presentation {
  color: white;
  height: 400px;
  position: relative;
  display: flex;
  display: flex;
  justify-content: center;
  gap: 24px;
}

.player-presentation-picture-container {
  position: relative;
  width: 300px;

  opacity: 0;
  transform: translateX(-100px);
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease;
}

.player-presentation-picture {
  width: 300px;
  background-size: cover;
  background-position: center center;
  border-top-left-radius: 32px;
  border-bottom-right-radius: 32px;
  aspect-ratio: 3 / 4;
  position: absolute;
}

.player-presentation-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  gap: 10px;

  opacity: 0;
  transform: translateX(100px);
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease;
}

.player-presentation-name {
  width: 350px;
  font-size: 48px;
  font-weight: bold;
  background-color: royalblue;
  border-top-right-radius: 32px;
}

.player-presentation-age {
  width: 100%;
  text-align: center;
  font-size: 40px;
  flex: 1;
}

.player-presentation-number {
  width: 100%;
  text-align: center;
  height: 230px;
  font-size: 100px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  padding-bottom: 50px;
}
